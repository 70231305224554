<template>
  <div class="container mx-auto max-w-screen-xl p-3">
    <main class="rounded-lg flex flex-col sm:flex-row-reverse sm:m-12 shadow-2xl">
      <div id="sidebar" class="w-full sm:max-w-sm p-8 rounded-r-lg bg-gradient-to-b from-green-600 via-teal-300 to-white">
        <div class="px-2 mb-12">
          <img
              src="./assets/img/photo-profile.jpg"
              alt="Profile Picture"
              class="rounded-full mx-auto mb-2"
              style="width: 250px; height: 250px; object-fit: cover; object-position: 0 40%"
          />
          <h1 class="text-center text-3xl font-semibold mb-2">Thierry Vaudelin</h1>
          <h2 class="text-center text-xl font-light"><i>Fullstack Developer</i></h2>
        </div>
        <div class="font-light text-lg px-2 mb-12">
          <h2 class="text-xl font-semibold mb-4">Contact</h2>
          <div class="flex items-center my-3">
            <img
                src="./assets/img/mail.svg"
                class="inline w-6 mr-4"
                alt="Mail icon"
            />
            <a href="mailto:">tvaudelin@gmail.com</a>
          </div>
          <div class="flex items-center my-3">
            <img
                src="./assets/img/phone.svg"
                class="inline w-6 mr-4"
                alt="Phone icon"
            />
            <a href="tel:+33755952727">07 55 95 27 27</a>
          </div>
          <div class="flex items-center my-3">
            <img
                src="./assets/img/home.svg"
                class="inline w-6 mr-4"
                alt="House icon"
            />
            <div>
              <p>61 route de la Gare</p>
              <p>13109 Simiane-Collongue</p>
            </div>
          </div>
        </div>
        <div class="font-light text-lg px-2 mb-12">
          <h2 class="text-xl font-semibold mb-4">Skills & Tools</h2>
          <ul class="list-none">
            <li>
              <label for="js-skill">JavaScript</label>
              <progress id="js-skill" max="100" value="70"></progress>
            </li>
            <li>
              <label for="php-skill">PHP</label>
              <progress id="php-skill" max="100" value="70"></progress>
            </li>
            <li>
              <label for="vue-skill">Vue / Vuex</label>
              <progress id="vue-skill" max="100" value="80"></progress>
            </li>
            <li>
              <label for="react-skill">React</label>
              <progress id="react-skill" max="100" value="55"></progress>
            </li>
            <li>
              <label for="react-skill">Angular</label>
              <progress id="angular-skill" max="100" value="40"></progress>
            </li>
            <li>
              <label for="symfony-skill">Symfony</label>
              <progress id="symfony-skill" max="100" value="65"></progress>
            </li>
            <li>
              <label for="apiplatform-skill">API-Platform</label>
              <progress id="apiplatform-skill" max="100" value="70"></progress>
            </li>
            <li>
              <label for="css-skill">CSS / SCSS</label>
              <progress id="css-skill" max="100" value="50"></progress>
            </li>
            <li>
              <label for="bootstrap-skill">Bootstrap</label>
              <progress id="bootstrap-skill" max="100" value="70"></progress>
            </li>
            <li>
              <label for="tailwind-skill">Tailwindcss</label>
              <progress id="tailwind-skill" max="100" value="50"></progress>
            </li>
            <li>
              <div>DevOps / DB</div>
              <div class="leading-8 flex flex-row  flex-wrap text-base font-normal mt-2">
                <span class="rounded-xl bg-gray-300 px-3 mr-1 mb-2">Docker</span>
                <span class="rounded-xl bg-gray-300 px-3 mr-1 mb-2">Gitlab</span>
                <span class="rounded-xl bg-gray-300 px-3 mr-1 mb-2">AWS</span>
                <span class="rounded-xl bg-gray-300 px-3 mr-1 mb-2">MySQL</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="font-light text-lg px-2 mb-12">
          <h2 class="text-xl font-semibold mb-4">Education</h2>
          <h3 class="font-semibold">Master in Management</h3>
          <p>2002 - 2006 | <b><i>ESCP Europe</i></b></p>
        </div>

        <div class="font-light text-lg px-2 mb-12">
          <h2 class="text-xl font-semibold mb-4">Languages</h2>
          <h3 class="font-semibold">French</h3>
          <p>Native</p>
          <h3 class="font-semibold">English</h3>
          <p>Fluent</p>
          <h3 class="font-semibold">Spanish</h3>
          <p>Fluent</p>
          <h3 class="font-semibold">Portuguese</h3>
          <p>Limited Working Proficiency</p>
        </div>
      </div>
      <div class="content rounded-l-lg w-full p-12">
        <div id="profile" class="prose max-w-none">
          <h2 class="section-headline">Executive Summary</h2>
          <ul>
            <li>Self-taught <b>full-stack web developer</b> with a 3-year experience in multiple languages (and frameworks):
                <ul>
                  <li><b>Front-end</b>: JavaScript (Vue.js 2, 3 - React), TypeScript (Angular), CSS / SCSS (Bootstrap - Tailwindcss)</li>
                  <li><b>Back-end</b>: PHP7/8 (Symfony 4, 5), MySQL, API REST (API Platform)</li>
                </ul>
            </li>
            <li>Earned a solid reputation for <b>efficiency</b>, <b>problem solving</b> and <b>management skills</b>.</li>
            <li>Extensive experience in fast paced environments: <b>quick learner</b>, great <b>multi-tasking
              abilities</b> and <b>time management skills</b>.</li>
            <li><b>Team player</b> with excellent <b>interpersonal relation skills</b>, able to communicate well with a variety of functions/departments.</li>
            <li><b>3 languages spoken fluently</b> (English, French and Spanish).</li>
          </ul>
        </div>
        <hr class="mt-8 mb-12" />
        <div id="experience" class="prose max-w-none">
          <h2 class="section-headline">Experiences</h2>
          <div>
            <h3>LOCATAIR SAS</h3>
            <section class="mb-6 w-full">
              <div class="lg:inline-block lg:w-3/12 lg:align-top italic mb-2">
                From&nbsp;Sept.&nbsp;2019:
              </div>
              <div class="lg:inline-block lg:w-8/12 w-full">
                Founder
              </div>
            </section>
            <section class="mb-6">
              <div class="lg:inline-block lg:w-3/12 lg:align-top italic mb-2">
                Description:
              </div>
              <div class="lg:inline-block lg:w-9/12">
                Developed a <b>fully functional digital platform</b> (<a href="https://locatair.fr" target="_blank">locatair.fr</a>)
                <b>streamlining the client-to-client rental market</b> in France. Went from 80 beta-testers to <b>1500+
                users</b> and processed <b>20+ transactions</b> seamlessly.
              </div>
            </section>
            <section class="mb-6">
              <div class="lg:inline-block lg:w-3/12 lg:align-top italic mb-2">
                Tasks:
              </div>
              <div class="lg:inline-block lg:w-9/12 prose-sm">

                <div class="relative">
                  <div class="w-9/12">
                    <span class="font-bold prose">Back-end Development </span>
                    <span class="text-xs inline-block"> (1/3<sup>rd</sup> of the time)</span>
                  </div>
                  <div
                      class="absolute cursor-pointer top-1 -right-8 text-xs rounded-xl bg-gray-300 px-2 py-1"
                      @click="toggle !== 1 ? toggle = 1 : toggle = 0"
                  >
                    {{ toggle !== 1 ? 'Show details' : 'Hide details' }}
                  </div>
                  <div
                      class="section-collapsable"
                      :class="[ toggle === 1 ? 'max-h-34 lg:max-h-22' : 'max-h-0' ]"
                  >
                    <ul>
                      <li>Realized with Symfony 4, API-Platform and MySQL.</li>
                      <li>Creation of specific controllers and managers to interact with external APIs (Mangopay,
                        Sell&Sign).
                      </li>
                      <li>Setup of normalization & denormalization processes using api-platform annotations or voters /
                        validators.
                      </li>
                      <li>Developed an automated notification system sending customized reminders to users (based on
                        crons
                        and event listeners).
                      </li>
                      <li>Setup of the marketing / email delivery system through async tasks (messenger)</li>
                      <li>Creation of a contract generator fulfilling Word templates and converting them into pdf.</li>
                      <li>Completed unit tests (PHPUnit).</li>
                    </ul>
                  </div>
                </div>

                <div class="relative">
                  <div class="mt-5 w-9/12">
                    <span class="font-bold prose">Front-end Development </span>
                    <span class="text-xs inline-block"> (1/3<sup>rd</sup> of the time)</span>
                  </div>
                  <div
                      class="absolute cursor-pointer top-1 -right-8 text-xs rounded-xl bg-gray-300 px-2 py-1"
                      @click="toggle !== 2 ? toggle = 2 : toggle = 0"
                  >
                    {{ toggle !== 2 ? 'Show details' : 'Hide details' }}
                  </div>
                  <div
                      class="section-collapsable"
                      :class="[ toggle === 2 ? 'max-h-38 lg:max-h-22' : 'max-h-0' ]"
                  >
                    <ul class="prose-sm">
                      <li>Realized with VueJS 2 / Vuex, SCSS and Bootstrap.</li>
                      <li>Development of the reservation funnel, including the following steps:
                        <ul>
                          <li>Selection of a candidate (appropriate notifications to be sent)</li>
                          <li>Payment of the security deposit</li>
                          <li>Lease contract generation / upload</li>
                          <li>Electronic signature process</li>
                        </ul>
                      </li>
                      <li>Development of a search engine displaying results on a map:
                        <ul>
                          <li>Use of mapbox and openstreetmap</li>
                          <li>Highlight the searched areas using polygons</li>
                          <li>Show results as markers positioned on the map</li>
                        </ul>
                      </li>
                      <li>Improvement of the registration process with an email verification step.</li>
                    </ul>
                  </div>
                </div>

                <div class="relative">
                  <div class="mt-5 w-9/12">
                    <span class="font-bold prose">Operational tasks </span>
                    <span class="text-xs inline-block"> (1/3<sup>rd</sup> of the time)</span>
                  </div>
                  <div
                      class="absolute cursor-pointer top-1 -right-8 text-xs rounded-xl bg-gray-300 px-2 py-1"
                      @click="toggle !== 3 ? toggle = 3 : toggle = 0"
                  >
                    {{ toggle !== 3 ? 'Show details' : 'Hide details' }}
                  </div>
                  <div
                      class="section-collapsable"
                      :class="[ toggle === 3 ? 'max-h-22 lg:max-h-14' : 'max-h-0' ]"
                  >
                    <ul class="prose-sm">
                      <li><u>Project</u>: Market study based on macro data and potential clients’ feedback (cold calls).
                        Definition of the functionalities to offer, wire-framing, production of the web-design (mostly
                        outsourced), content definition.
                      </li>
                      <li><u>Marketing</u>: Creation of a tagging plan, SEO optimization, AB testing on Facebook,
                        production
                        of newsletters and multiple videos (animations), creation of a blog, animation of webinars.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <section class="lg:flex mb-6">
              <div class="lg:inline-block lg:w-3/12 lg:align-top italic mb-2">
                Tools:
              </div>
              <div class="lg:w-10/12 flex flex-row flex-wrap">
                <div class="rounded-xl bg-gray-300 px-3 mr-1 mb-2">Vue</div>
                <div class="rounded-xl bg-gray-300 px-3 mr-1 mb-2">Vuex</div>
                <div class="rounded-xl bg-gray-300 px-3 mr-1 mb-2">Bootstrap</div>
                <div class="rounded-xl bg-gray-300 px-3 mr-1 mb-2">Symfony</div>
                <div class="rounded-xl bg-gray-300 px-3 mr-1 mb-2">Api Platform</div>
                <div class="rounded-xl bg-gray-300 px-3 mr-1 mb-2">Docker</div>
                <div class="rounded-xl bg-gray-300 px-3 mr-1 mb-2">Gitlab</div>
                <div class="rounded-xl bg-gray-300 px-3 mr-1 mb-2">AWS</div>
                <div class="rounded-xl bg-gray-300 px-3 mr-1 mb-2">MySQL</div>
              </div>
            </section>
          </div>
          <div class="relative flex py-5 justify-center">
            <div class="w-9/12 border-t border-gray-400"></div>
          </div>
          <div>
            <h3 style="text-transform: uppercase">Société Générale</h3>
            <section class="mb-6 w-full">
              <div class="lg:inline-block lg:w-3/12 lg:align-top italic mb-2">
                From&nbsp;Sept.&nbsp;2006
                To&nbsp;Sept.&nbsp;2019
              </div>
              <div class="lg:inline-block lg:w-8/12 w-full">
                Marketer & Sales for Structured Products (Solutions division)
              </div>
            </section>
            <section class="mb-6">
              <div class="lg:inline-block lg:w-3/12 lg:align-top italic mb-2">
                Description:
              </div>
              <div class="lg:inline-block lg:w-9/12">
                <ul>
                  <li>13 years spent mostly in Spain (6 years) and in the USA (5 years).</li>
                  <li>9 years working in the Marketing team (5 years as head of the North and South America region).</li>
                  <li>4 years working as a Sales person covering Spain and Portugal.</li>
                </ul>
              </div>
            </section>
            <section class="mb-6">
              <div class="lg:inline-block lg:w-3/12 lg:align-top italic mb-2">
                Jobs:
              </div>
              <div class="lg:inline-block lg:w-9/12">
                <div class="relative">
                  <div class="w-9/12">
                    <span class="font-bold prose">Product Pusher – Life Insurance</span>
                  </div>
                  <div
                      class="absolute cursor-pointer top-1 -right-8 text-xs rounded-xl bg-gray-300 px-2 py-1"
                      @click="toggle !== 4 ? toggle = 4 : toggle = 0"
                  >
                    {{ toggle !== 4 ? 'Show details' : 'Hide details' }}
                  </div>
                  <div
                      class="section-collapsable"
                      :class="[ toggle === 4 ? 'max-h-16 lg:max-h-14' : 'max-h-0' ]"
                  >
                    <ul>
                      <li>2018 - 2019</li>
                      <li>Paris, France</li>
                      <li>Coverage: Europe</li>
                      <li><u>Main Mission</u>: Defining and pushing products throughout Europe based on each country / company distinctive features.</li>
                    </ul>
                  </div>
                </div>
                <div class="relative">
                  <div class="mt-5 w-9/12">
                    <span class="font-bold prose">Sales – Cross Asset Solutions</span>
                  </div>
                  <div
                      class="absolute cursor-pointer top-1 -right-8 text-xs rounded-xl bg-gray-300 px-2 py-1"
                      @click="toggle !== 5 ? toggle = 5 : toggle = 0"
                  >
                    {{ toggle !== 5 ? 'Show details' : 'Hide details' }}
                  </div>
                  <div
                      class="section-collapsable"
                      :class="[ toggle === 5 ? 'max-h-28 lg:max-h-18' : 'max-h-0' ]"
                  >
                    <ul>
                      <li>2015 - 2018</li>
                      <li>Location: Madrid, Spain</li>
                      <li>Coverage: Iberia</li>
                      <li><u>Main Mission</u>: Promoting both Vanilla Products (e.g. Participation Products, Autocalls,
                        CLNs) & Bespoke Solutions (e.g. Repacks, Unsecured Lending, Structured Loans, Secured Listed
                        Warrants) towards Distribution Networks & Institutional Clients.</li>
                    </ul>
                  </div>
                </div>
                <div class="relative">
                  <div class="mt-5 w-9/12">
                    <span class="font-bold prose">Head of Marketing – Cross Asset Solutions</span>
                  </div>
                  <div
                      class="absolute cursor-pointer top-1 -right-8 text-xs rounded-xl bg-gray-300 px-2 py-1"
                      @click="toggle !== 6 ? toggle = 6 : toggle = 0"
                  >
                    {{ toggle !== 6 ? 'Show details' : 'Hide details' }}
                  </div>
                  <div
                      class="section-collapsable"
                      :class="[ toggle === 6 ? 'max-h-24 lg:max-h-16' : 'max-h-0' ]"
                  >
                    <ul>
                      <li>2010 - 2015</li>
                      <li>Location: New-York, USA</li>
                      <li>Coverage: North and South America</li>
                      <li><u>Main Mission</u>: Managing a team of 5 in charge of designing and producing detailed
                        commercial presentations, term sheets and other marketing materials for distribution to
                        clients and / or potential investors.
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="relative">
                  <div class="mt-5 w-9/12">
                    <span class="font-bold prose">Marketer for Iberia & SG Index</span>
                  </div>
                  <div
                      class="absolute cursor-pointer top-1 -right-8 text-xs rounded-xl bg-gray-300 px-2 py-1"
                      @click="toggle !== 7 ? toggle = 7 : toggle = 0"
                  >
                    {{ toggle !== 7 ? 'Show details' : 'Hide details' }}
                  </div>
                  <div
                      class="section-collapsable"
                      :class="[ toggle === 7 ? 'max-h-30 lg:max-h-20' : 'max-h-0' ]"
                  >
                    <ul>
                      <li>2007 - 2008</li>
                      <li>Location: Madrid, Spain</li>
                      <li>Coverage: Iberia (structured products) & Global (SG Index) </li>
                      <li><u>Main Mission</u>: Delivering marketing services in order to assist sales increasing the
                        market share of SG Structured Products in Spain and Portugal and develop the global brand
                        awareness / business activity of SG Index and Commodity-linked products. Managing a team of 4
                        (2 people in Madrid + 2 people in Paris).
                      </li>
                    </ul>
                  </div>
                </div>

              </div>
            </section>
            <section class="lg:flex mb-6">
              <div class="lg:inline-block lg:w-3/12 lg:align-top italic mb-2">
                Tools:
              </div>
              <div class="lg:w-10/12 flex flex-row flex-wrap">
                <div class="rounded-xl bg-gray-300 px-3 mr-1 mb-2">Excel</div>
                <div class="rounded-xl bg-gray-300 px-3 mr-1 mb-2">VBA</div>
                <div class="rounded-xl bg-gray-300 px-3 mr-1 mb-2">Powerpoint</div>
                <div class="rounded-xl bg-gray-300 px-3 mr-1 mb-2">Bloomberg</div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>

export default {
  name: 'App',

  data() {
    return {
      toggle: 0
    }
  },

  watch: {
    toggle (toggle) {
      console.log(toggle)
    }
  }
}
</script>

<style>
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }

  progress {
    margin-top: 0.5rem;
    width: 100%;
    height: 10px;
    margin-bottom: 0.4rem;
  }

  progress::-webkit-progress-bar {
    border-radius: 10px;
    background-color: #d1d5db;
    box-shadow: 0 2px 6px #555;
  }

  progress::-webkit-progress-value {
    border-radius: 10px 0 0 10px;
    background-image: linear-gradient(36deg, #144d49, #1eadab);
  }

  .section-headline {
    @apply font-bold sm:text-xl uppercase tracking-widest border-green-500 border-l-8 pl-2;
    color: #10b981 !important;
  }

  .section-collapsable {
    overflow: hidden;
    transition: max-height 600ms ease-out;
  }
</style>
